.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  margin-top: 1.5rem;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
  [data-theme="dark"] .site-layout-content {
    background: #141414;
  }

.ant-layout-content {
  padding: 1.5rem 3rem!important;
}

span.ant-descriptions-item-label {
  font-weight: 500;
}